@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

[data-theme="light"] {
  --primary-bg-color: #f1f3f6;
  --body-font-family: "Karla", sans-serif;
  --heading-font-family: "Rubik", sans-serif;
  --base-dark-color: #ffffff;
  --base-dark-grey: #8d9093;
  --input-border-grey: #5d6063;
  --button-primary-bg-color: #ef550a;
  --button-dark-bg-color: #1d2028;
  --button-light-bg-color: #e5e7ea;
  --card-body-bg-color: #ffffff;
  --font-color-text: #1d2028;
  --pop-color-text:#1d2028;
  --inputbox-bg-color: #f1f3f6;
  --border-grey-color: #f1f3f6;
  --footer-bg-color: #444548;
  --searchbar-textbox-color: #e5e7ea;
  --header-bg-color: #ffffff;
  --placeholder-text-color: #8d9093;
  --sidebar-bg-color: #e5e7ea;
  --sidebar-hover-bg-color: #ef550a;
  --sidebar-open-active-bg: #f1f3f6;
  --card-header-bg-color: #e5e7ea;
  --card-border-bg-color: #1d2028;
  --table-stripped-odd-bg: #e5e7ea;
  --table-stripped-even-bg: #f0f2f6;
  --text-orange-color: #ef550a;
  --chatbox-color: #e5e7ea;

  --back-arrow-color: #aeb1b4;
  --lang-border-grey: #dddddd;
  --lang-icon-background: #444548;
  --search-box-background: #444548;
  --bottomnav-active-color: #ffffff;
  --color-white: #ffffff;
  --bottomsheet-bg-color: #1d2028;
  --chat-header-background: #444548;
  --chat-sentmsg-background: #ef550a;
  --chat-sentmsg-color: #ffffff;
  --chat-repliesmsg-background: #444548;
  --chat-repliesmsg-color: #ffffff;
  --button-secondary-color: #444548;
  --bottompaper-nav-color: #aeb1b4;
  --card-icon-color: #ef550a;
  --swal-bg-color: #1d2028;
  --reportbutton-secondary-color: #1d2028;
  --chat-bg-screen-color: #1d2028;
  /* --inputbox-bg-color:#8D9093; */
  --color-black:#000;
  --icons-black:#000;
  --text-white:#000;
  --small-cards:#aaaaaa6e;
  --modal-header:#444548;
  --btn-danger:#c82333;
  --border-color: #bd2130;
  --bg-danger : #c82333;
  --btn-info: #17a2b8;
  --border-info: #17a2b8;
  --calender-color: invert(0);
  --tab-bg-color:#e5e7ea;
  --anchor-color: #000;
  --anchorcolor-hover:#167495;
  --arrow-color:#444548;
  --filter-header: #444548;
  --inputcolor-btn:#ffffff;
  --semi-tab-color:#F1F3F6;
  --home-card:rgb(0 0 0 / 53%) !important;
  --btn-sm:#ef550a;
  --btn-border-sm:#ef550a;
}
[data-theme="dark"] {
  --pop-color-text:#1d2028;
  --primary-bg-color: #1d2028;
  --body-font-family: "Karla", sans-serif;
  --heading-font-family: "Rubik", sans-serif;
  --base-dark-color: #ffffff;
  --inputcolor-btn:#1d2028;
  --base-dark-grey: #8d9093;
  --input-border-grey: #5d6063;
  --button-primary-bg-color: #ef550a;
  --filter-header:#ef550a;
  --button-dark-bg-color: #ffffff;
  --button-light-bg-color: #1d2028;
  --card-body-bg-color: #444548;
  --font-color-text: #ffffff;
  --inputbox-bg-color: #1d2028;
  --border-grey-color: #f1f3f6;
  --footer-bg-color: #444548;
  --searchbar-textbox-color: #444548;
  --header-bg-color: #444548;
  --placeholder-text-color: #8d9093;
  --sidebar-bg-color: #444548;
  --sidebar-hover-bg-color: #ef550a;
  --sidebar-open-active-bg: #1d2028;
  --card-header-bg-color: #1d2028;
  --card-border-bg-color: #1d2028;
  --table-stripped-odd-bg: #1d2028;
  --table-stripped-even-bg: #444548;
  --text-orange-color: #ef550a;
  --chatbox-color: #444548;

  --back-arrow-color: #aeb1b4;
  --lang-border-grey: #dddddd;
  --lang-icon-background: #444548;
  --search-box-background: #444548;
  --bottomnav-active-color: #ffffff;
  --color-white: #ffffff;
  --bottomsheet-bg-color: #1d2028;
  --chat-header-background: #444548;
  --chat-sentmsg-background: #ef550a;
  --chat-sentmsg-color: #ffffff;
  --chat-repliesmsg-background: #444548;
  --chat-repliesmsg-color: #ffffff;
  --button-secondary-color: #444548;
  --bottompaper-nav-color: #aeb1b4;
  --card-icon-color: #ef550a;
  --swal-bg-color: #1d2028;
  --reportbutton-secondary-color: #1d2028;
  --chat-bg-screen-color: #1d2028;
  /* --inputbox-bg-color:#8D9093; */
  --btn-danger:#ff5668;
  --border-color: #ff5668;
  --bg-danger: #f86c6b;
  --btn-danger-hover:#f64846;
  --bordercolor-danger-hover:#f63c3a;
  --anchor-color:#20a8d8;
  --anchorcolor-hover:#167495;
  --btn-info: #41d5e2;
  --border-info: #41d5e2;
  --small-cards:#f0f3f5;
  --arrow-color:#4382ac;
  --calender-color:invert(1);
  --semi-tab-color:#2B3645;
  --home-card: #444548;
  --btn-sm:#1d2028;
  --btn-border-sm: #1d2028;
}
  
  

*::-webkit-scrollbar {
  width: 0.5em !important;
  height: 0.5em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-bg-color);
  outline: 1px solid slategrey;
}
body {
  font-family: var(--body-font-family) !important;
}
.text-warning {
  color: var(--text-orange-color) !important;
}
.btn-success {
  color: #fff;
  background-color: #43bc13;
  border-color: #43bc13;
}

.btn-danger {
  color: #fff;
  background-color: var(--btn-danger);
  border-color: var(--border-color);
}

.btn-danger:hover{
    color: #fff;
    background-color: var(--btn-danger-hover);
    border-color: var(--bordercolor-danger-hover) !important;
}
.btn-info {
  color: #23282c;
  background-color: #41d5e2;
  border-color: #41d5e2;
}
.form-control {
  background-color: var(--inputbox-bg-color) !important;
  border-color: var(--inputbox-bg-color) !important;
  color: var(--font-color-text);
}
.form-control:focus {
  background-color: var(--inputbox-bg-color) !important;
  border-color: var(--inputbox-bg-color) !important;
  color: var(--font-color-text) !important;
}
.css-bg1rzq-control {
  background-color: var(--inputbox-bg-color) !important;
  border-color: var(--inputbox-bg-color) !important;
  border-radius: 8px !important;
}
.css-dvua67-singleValue {
  color: var(--font-color-text) !important;
}
.inbox .css-bg1rzq-control {
  background-color: var(--card-body-bg-color) !important;
  border-color: var(--card-body-bg-color) !important;
  border-radius: 8px !important;
}
.bgLogin {
  background-image: none !important;
  background-size: cover;
  background: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-color: var(--primary-bg-color) !important;
}
.login-card {
  background-color: var(--card-body-bg-color) !important;
  border: 5px solid var(--card-body-bg-color) !important;
  box-shadow: 8px 8px 20px rgb(6 13 48 / 4%);
  border-radius: 12px;
}
.login-card .input-group-text i {
  color: var(--font-color-text) !important;
}
.login-card h1.coloryellow {
  color: var(--font-color-text) !important;
  font-family: var(--heading-font-family);
}
.login-card h4 {
  color: var(--font-color-text) !important;
  font-family: var(--heading-font-family);
}
.login-card p.colorwhite {
  color: var(--font-color-text) !important;
  font-family: var(--body-font-family) !important;
}
.login-card h1.coloryellow:after {
  background-color: var(--font-color-text) !important;
}
.login-card .btn-primary {
  background-color: var(--button-primary-bg-color) !important;
  border-color: var(--button-primary-bg-color) !important;
}
.login-card .btn-link.customcss {
  color: var(--font-color-text) !important;
  font-family: var(--body-font-family) !important;
}
.login-card .form-control {
  background-color: var(--inputbox-bg-color);
  border-color: var(--inputbox-bg-color);
  color: var(--font-color-text);
}
.app-body {
  background-color: var(--primary-bg-color) !important;
}
.main .nav-tabs .nav-link {
  background-color: var(--button-light-bg-color);
  color: var(--font-color-text);
}
.main .nav-tabs .nav-link:hover {
  color: var(--font-color-text);
}
.main .nav-tabs .nav-link.active {
  background-color: var(--button-primary-bg-color);
  color: var(--color-white);
}
.app-body .card.bg-darkblue {
  background-color: var(--card-body-bg-color);
}
.app-body .card.bg-darkblue .card-body .text-value {
  color: var(--font-color-text) !important;
  font-family: var(--body-font-family) !important;
}
.highcharts-axis-title,
.highcharts-axis-labels,
.highcharts-axis-labels text,
.highcharts-legend-item text,
.highcharts-data-label {
  color: var(--font-color-text) !important;
  fill: var(--font-color-text) !important;
  font-family: var(--body-font-family) !important;
}

.inbox .bg-white {
  background-color: var(--card-body-bg-color) !important;
}
.btn-outline-dark {
  background-color: var(--button-primary-bg-color) !important;
  border: var(--button-primary-bg-color) !important;
}
.btn-primary {
  background-color: var(--button-primary-bg-color) !important;
  border-color: var(--button-primary-bg-color) !important;
}
label.text-white,
label {
  color: var(--font-color-text) !important;
}
.btn-outline-light {
  color: var(--font-color-text) !important;
  font-family: var(--body-font-family) !important;
}
.case_item h6.text-white {
  color: var(--font-color-text) !important;
  font-family: var(--heading-font-family) !important;
}
.case_item small {
  color: var(--font-color-text) !important;
  font-family: var(--body-font-family) !important;
}
.inbox .card .case_item.active {
  background-color: var(--primary-bg-color) !important;
}

.inbox .card {
  border-radius: 12px !important;
}

.case_item .badge-light ,
.badge-light {
  background-color: var(--button-secondary-color) !important;
  background-color: var(--small-cards) !important;
}

.inbox .bg-white {
  color: var(--font-color-text) !important;
  font-family: var(--body-font-family) !important;
}
.tab-content {
  /* background-color: var(--card-body-bg-color) !important;
  border-color: var(--card-body-bg-color) !important; */
  box-shadow: 8px 8px 20px rgb(6 13 48 / 4%) !important;
  border-radius: 12px;
}
.form-control-file {
  color: var(--font-color-text) !important;
}
.card {
  background-color: var(--card-body-bg-color) !important;
  /* border: 1px solid var(--card-header-bg-color) !important; */
  box-shadow: 8px 8px 20px rgb(6 13 48 / 4%) !important;
  border-radius: 12px !important;
}

.card-header,
.card-header.bg-transparent {
  /* background-color: var(--card-header-bg-color) !important; */
  background-color: var(--card-header-bg-color)!important;
    border-color: var(--card-header-bg-color)!important;
    color: var(--font-color-text)!important;
    border-radius: 12px!important;
}

.accordion .accordion-comp .card,
.accordion  .accordion-comp .card-header{
  border-color: var(--card-border-bg-color) !important;
  border: 1px solid !important;
}

.accordion .card,
.accordion .card-header{
  border-color: var(--card-border-bg-color) !important;
  border: 1px solid !important;
  border-bottom: 2px solid !important;
}

.footer {
  background-color: var(--footer-bg-color) !important;
}
.header {
  background-color: var(--header-bg-color) !important;
  color: var(--font-color-text) !important;
  font-family: var(--heading-font-family) !important;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--placeholder-text-color) !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--placeholder-text-color) !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--placeholder-text-color) !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: var(--placeholder-text-color) !important;
}
.eyeiconcss {
  color: var(--font-color-text) !important;
}
header.app-header {
  background-color: var(--header-bg-color) !important;
}
header.app-header .project-title {
  color: var(--font-color-text) !important;
  font-family: var(--heading-font-family) !important;
}

header.app-header .btn.btn-outline-light {
  background-color: var(--button-light-bg-color)!important;
  border-color: var(--button-light-bg-color)!important;
  /* color: var(--color-white)!important;
   */
}
.sidebar {
  background-color: var(--sidebar-bg-color) !important;
  border-right: 1px solid var(--sidebar-bg-color) !important;
}
.sidebar .nav-link {
  color: var(--font-color-text) !important;
}
.sidebar .nav-link .nav-icon {
  color: var(--font-color-text) !important;
}
.sidebar .nav-link:hover {
  background-color: var(--sidebar-hover-bg-color) !important;
  color: var(--color-white) !important;
}

.sidebar .nav-link:hover i{
  color: var(--color-white) !important;
}

.sidebar .nav-link.active:hover i
{

  color: var(--font-color-text) !important;
}

.sidebar .nav-dropdown.open {
  background-color: var(--sidebar-bg-color) !important;
}
.sidebar .nav-link.active {
  background-color: var(--sidebar-open-active-bg) !important;
  border-right: 3px solid var(--sidebar-bg-color) !important;
  color: var(--color-white) !important;
}


.sstab.nav-tabs {
  margin-bottom: 5px !important;
  border-bottom: 0 !important;
}
.sstab .nav-link {
  background-color: var(--card-body-bg-color);
}

.sstab > li .nav-arrow {
  border-color: var(--primary-bg-color) var(--primary-bg-color)
    var(--primary-bg-color) var(--card-body-bg-color) !important;
}
.sstab > li {
  border-right: 12px solid var(--primary-bg-color) !important;
  border-left: 12px solid var(--primary-bg-color) !important;
}
.sstab .nav-link {
  background-color: var(--card-body-bg-color) !important;
  color: var(--font-color-text) !important;
}
.sstab > li .nav-wedge {
  border-color: var(--card-body-bg-color) var(--card-body-bg-color)
    var(--card-body-bg-color) transparent !important;
}
.sstab > li.active a,
.sstab > li.active a:hover {
  background-color: var(--button-primary-bg-color) !important;
  color: var(--color-white) !important;
}

.sstab > li.active .nav-wedge {
  border-color: var(--button-primary-bg-color) var(--button-primary-bg-color)
    var(--button-primary-bg-color) transparent !important;
}
.sstab > li.active .nav-arrow {
  border-color: transparent transparent transparent
    var(--button-primary-bg-color) !important;
}
.tab-pane .card {
  box-shadow: none !important;
  /* border: none !important; */
}

.tab-pane .dropdown .btn-outline-light {
  background-color: var(--inputbox-bg-color);
}
.dropdown .dropdown-toggle.btn {
  background-color: var(--inputbox-bg-color);
  color: var(--font-color-text) !important;
}
.tab-pane .card .sstab > li .nav-arrow {
  border-color: var(--card-body-bg-color) var(--card-body-bg-color)
    var(--card-body-bg-color) var(--primary-bg-color) !important;
}
.tab-pane .card .sstab .nav-link {
  background-color: var(--primary-bg-color) !important;
  color: var(--font-color-text) !important;
}
.tab-pane .card .sstab > li .nav-wedge {
  border-color: var(--primary-bg-color) var(--primary-bg-color)
    var(--primary-bg-color) transparent !important;
}
.tab-pane .card .sstab > li {
  border-right: 12px solid var(--card-body-bg-color) !important;
  border-left: 12px solid var(--card-body-bg-color) !important;
}
.tab-pane .card .sstab > li.active a,
.sstab > li.active a:hover {
  background-color: var(--button-primary-bg-color) !important;
  color: var(--color-white) !important;
}

.tab-pane .card .sstab > li.active .nav-wedge {
  border-color: var(--button-primary-bg-color) var(--button-primary-bg-color)
    var(--button-primary-bg-color) transparent !important;
}
.tab-pane .card .sstab > li.active .nav-arrow {
  border-color: transparent transparent transparent
    var(--button-primary-bg-color) !important;
}
.accordion .card .btn-custom {
  color: var(--font-color-text) !important;
}
.tab-pane .accordion .card {
  border-color: var(--card-header-bg-color) !important;
}

.border-color .tab-pane .accordion .card {
  border-color: transparent !important;
}
.accordion-comp .tab-pane .accordion .card {
  border-color: none !important;
}
.tab-pane .nav-tabs .nav-link {
  background-color: var(--button-light-bg-color) !important;
}
.tab-pane .nav-tabs .nav-link.active {
  color: var(--color-white) !important;
  background: var(--button-primary-bg-color) !important;
}
.modalfilter .modal-header {
  background-color: var(--filter-header) !important;
}

.modal-header{
  background-color: var(--button-primary-bg-color) !important;
}
.modal-body {
  background-color: var(--card-body-bg-color) !important;
}
.modal-footer {
  background-color: var(--card-body-bg-color) !important;
}
.modal-body .nav-tabs .nav-link.active,
#services .nav-tabs .nav-link.active,
#survey .nav-tabs .nav-link.active {
  color: var(--color-white) !important;
  background: var(--button-primary-bg-color) !important;
}
.modal-body .nav-tabs .nav-link {
  background-color: var(--button-light-bg-color) !important;
  color: var(--color-black) !important;
}
.nearmeTab .btn-light.active {
  color: var(--color-white) !important;
  background: var(--button-primary-bg-color) !important;
}
.nearmeTab .btn-light {
  background-color: var(--button-light-bg-color) !important;
  color: var(--placeholder-text-color) !important;
}
#mapList li.list-group-item.list-group-item-action {
  background-color: var(--card-header-bg-color) !important;
  border-bottom: 1px solid var(--card-header-bg-color) !important;
}
#mapList h5 {
  color: var(--font-color-text) !important;
}
#mapList .text-muted {
  color: var(--font-color-text) !important;
}
#nearmeConfig .card .card-header {
  border-radius: 12px 12px 0 0 !important;
}
.table thead th {
  background-color: var(--footer-bg-color) !important;
  color: var(--color-white) !important;
  font-size: var(--heading-font-family) !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  color: var(--font-color-text) !important;
  background-color: var(--table-stripped-odd-bg) !important;
}
.table-striped tbody tr:nth-of-type(even) {
  background: var(--table-stripped-even-bg) !important;
  color: var(--font-color-text) !important;
}
#pageDropDown {
  background-color: var(--button-primary-bg-color) !important;
  border-color: var(--button-primary-bg-color) !important;
  color: var(--color-white) !important;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: var(--button-primary-bg-color) !important;
  border-color: var(--button-primary-bg-color) !important;
}
.configfilter-page .card .card-header {
  border-radius: 12px 12px 0 0 !important;
}
.dashboard-boxes .card .card-body {
  background-color: var(--home-card) !important;
  border-radius: 12px 12px 0 0;
}
.dashboard-boxes .card .card-footer {
  border-radius: 0 0 12px 12px;
}
.indicators-overview .bg-cloudburst {
  background-color: var(--primary-bg-color) !important;
  color: var(--font-color-text) !important;
}
.indicators-overview div.text-left {
  color: var(--font-color-text) !important;
}
.inbox .bg-white {
  border: 0;
  border-radius: 12px;
  margin-top: 15px;
  box-shadow: 8px 8px 20px rgb(6 13 48 / 4%) !important;
}
.homeIcons li a div {
  border-radius: 12px;
}
.custom-dropdown .dropdown .dropdown-toggle {
  background-color: var(--card-body-bg-color) !important;
  border-radius: 8px !important;
}
.chart-wrapper .highcharts-title tspan {
  color: var(--font-color-text) !important;
  fill: var(--font-color-text) !important;
  font-family: var(--body-font-family) !important;
}
.react-bootstrap-table.table-responsive {
  border: 0 !important;
}
.inbox h5 {
  color: var(--font-color-text) !important;
}
.table_react_bootstrap .react-bs-table-csv-btn {
  background-color: var(--button-primary-bg-color) !important;
  /* color: var(--font-color-text) !important; */
  border: 1px solid var(--button-primary-bg-color) !important;
}
.PhoneInputCountrySelect,
.PhoneInputInput {
  background-color: var(--inputbox-bg-color) !important;
  border-color: var(--inputbox-bg-color) !important;
}
.btn-custom i {
  color: var(--font-color-text) !important;
}
.theme-color-btn {
  background-color: transparent;
  border: none;
  color: var(--font-color-text);
}
.gm-style .gm-style-iw-c {
  background-color: rgb(255 255 255 / 80%);
  width: 280px;
  padding-right: 12px !important;
}
.gm-style-iw-d div .card {
  background-color: transparent !important;
  border: none !important;
}
.facilityDetailsInsideDiv p {
  color: #1d2028 !important;
  font-family: var(--body-font-family);
  text-align: left;
  font-weight: 500;
}
.facilityDetailsInsideDiv p .badge {
  background-color: var(--button-primary-bg-color);
  border: 1px solid #fff;
  color: var(--color-white);
}
.gm-style-iw.gm-style-iw-c .gm-ui-hover-effect:focus {
  border: 0;
  box-shadow: none;
  outline: none;
}
.gm-style .gm-style-iw-d {
  overflow: auto !important;
  max-height: 220px !important;
}
.gm-style .gm-style-iw-t::after {
  background: linear-gradient(
    45deg,
    rgb(255 255 255 / 80%) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(255, 255, 255, 0) 100%
  );
}
.css-1szy77t-control {
  background-color: var(--inputbox-bg-color) !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 0 !important;
}
/* .leaflet-bar a, .leaflet-bar a:hover{
    background-color: var(--sidebar-bg-color) !important;
    border-bottom: 1px solid #1d2028 !important;
    color: var(--font-color-text) !important;
} */
.leaflet-right {
  right: -3px !important;
}
.leaflet-control.leaflet-bar .btn-secondary {
  margin-top: 2px !important;
  background: #1d2028 !important;
  border-color: #fff !important;
  color: #fff !important;
}
.dropdown .dropdown-header {
  background: var(--sidebar-bg-color) !important;
  color: var(--font-color-text) !important;
}
.dropdown .dropdown-item {
  background-color: var(--button-light-bg-color) !important;
  color: var(--font-color-text) !important;
}
.dropdown .dropdown-item:active{
  background-color: var(--sidebar-open-active-bg) !important;
}
.userchatPage {
  background-color: var(--card-body-bg-color) !important;
  border-radius: 12px;
}
.userchatPage .chatlistaccordionholder {
  background: var(--primary-bg-color) !important;
  border-radius: 12px 0 0 12px;
  padding: 8px;
  border-right: 1px solid var(--sidebar-bg-color);
}
.userchatPage .chatlistaccordionholder .accordion .bg-white.card {
  border: 0;
  border-bottom: 1px solid #6b6565;
  border-radius: 0px !important;
  margin: 0;
}
.userchatPage .chatlistaccordionholder .accordion .bg-white.card.active {
  border: 0;
  border-bottom: 1px solid #6b6565;
  border-radius: 0px !important;
}
.userchatPage .chatlistaccordionholder .accordion .bg-white.card .card-header {
  border-radius: 0px !important;
}
.userchatPage .chatsectionwrap {
  background: var(--primary-bg-color) !important;
  border: 0;
  border-radius: 0 12px 12px 0 !important;
}
.chatlistaccordionholder .accordion .bg-white.card .card-header:hover {
  background: var(--button-secondary-color)
    radial-gradient(circle, transparent 1%, var(--button-secondary-color) 1%)
    center/15000% !important;
}
.chatlistaccordionholder .accordion .bg-white.card.active .card-header {
  background-color: var(--button-secondary-color) !important;
}
.userchatPage .chatsectionwrap .inbox_chat {
  background-color: var(--chatbox-color) !important;
  border: 1px solid var(--chatbox-color) !important;
}
.userchatPage .chatsectionwrap .sendbtnholder {
  background-color: var(--chatbox-color) !important;
}
.userchatPage .chatsectionwrap .sendbtnholder .form-control {
  color: var(--font-color-text) !important;
}
.userchatPage .chatsectionwrap .request_chat {
  background-color: var(--chat-bg-screen-color) !important;
  border-radius: 12px 12px 0 12px;
}
.userchatPage .chatsectionwrap .inbox_chat .request_chat .chat_content {
  background-color: transparent !important;
  color: var(--font-color-text) !important;
}
.userchatPage .chatsectionwrap .inbox_chat .request_chat .dateTime {
  color: var(--font-color-text) !important;
}

.userchatPage .user-chat-serach-box {
  color: var(--font-color-text) !important;
}
.facilityDetailsPopupDiv {
  text-align: center;
}
.facilityDetailsPopupDiv h5 {
  text-align: left;
  font-size: 1rem;
  margin-bottom: 0;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.gm-style-iw-d .table_react_bootstrap {
  padding: 0px 1px;
}
.gm-style-iw.gm-style-iw-c .gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
}
.facilityDetailsPopupDiv .facilityDetailsInsideDiv {
  text-align: left;
}

.dashboard-boxes .card .card-footer {
  background-color: var(--sidebar-bg-color) !important;
  color: var(--font-color-text) !important
}

.sidebar{
  color: var(--font-color-text) !important;
}

.header.app-header .btn.btn-outline-light{
  background-color: var(--sidebar-bg-color) !important;
  color: var(--color-black) !important;
}

.input-group-prepend .btn {
  background-color: var(--inputcolor-btn) !important;
}

.react-bootstrap-table-pagination-total {
  color: var(--color-black) !important;
}

.dropdown .dropdown-item {
  background-color: var(--button-light-bg-color) !important;
}

.dropdown .dropdown-item a{
  text-decoration: none;
  color: var(--anchor-color) !important;

}

.dropdown .dropdown-item a:hover{
  text-decoration: underline;
  color: (--anchorcolor-hover);
}
.operationalCards .card-body {
  background-color: var( --card-body-bg-color) !important;
  color: var(--color-black) !important;
} 

.modal-footer,
.modal-body
{
  background-color: var(--sidebar-bg-color) !important;
}

.service-cards .tab-content {
  background-color: var(--semi-tab-color) !important;
}


.report-tab .tab-content {
  background-color: var(--semi-tab-color) !important;
}

.cursorPointer{
  color: var(--icons-black) !important;
}

.tab-content {
  background-color: var(--card-body-bg-color) !important;
}

.modal-dialog .btn-secondary {
  background-color: var(--button-primary-bg-color) !important;
  border: var(--button-primary-bg-color) !important;
  color: var(--color-white) !important;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before ,
.custom-control-input:not(:disabled):active~.custom-control-label::after {
  background-color: var(--button-primary-bg-color) !important;
}

/* .custom-control-input:checked~.custom-control-label::before {
  background-color: var(--button-primary-bg-color) !important;
} */

.operationalCards h5.text-center {
  color: var(--color-black) !important;
}

.swal-button{
  background-color: var(--button-primary-bg-color) !important;
}

.configfilter-page .lighttheme h4{
  color: var(--color-black) !important;
}

.operationalCards .text-white {
  color: var(--color-black) !important;
}

.operationalsmallCards .text-white {
  color: var(--font-color-text) !important;
}

.indicators-overview .fas {
  color: var(--font-color-text) !important;
}

.servicesdiv .card-body {
  color: var(--font-color-text) !important;
}

.bg-cloudburst{
  color: var(--color-white) !important;
}

#pdfSection .text-white{
  color: var(--font-color-text) !important;
}

#pdfSection .dropdown .dropdown-toggle.btn{
  color: var(--color-white) !important;
}

.operationalOverview .text-white{
  color: var(--font-color-text) !important;
}

.operationalOverview .dropdown .dropdown-toggle.btn{
  color: var(--color-white) !important;
}

.configfilter-page h4 {
  color: var(--color-black) !important;
}

#menu-selection-modal .ant-tree{
  color: var(--color-black) !important;
}

.btn-danger{
    background-color: var(--btn-danger) !important;
    border-color: var(--border-color) !important;
}

/* .btn-info ,
.btn-info:hover{
  background-color: var(--btn-info) !important;
  border-color: var(--border-info) !important;
  color: var(--color-white) !important;
} */

.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: var(--button-primary-bg-color) !important;
}

.border-bottom.card {
  border: 1px solid var(--card-header-bg-color) !important;
}

#button {
  background: var(--arrow-color) ;
  color: var(--color-white);
}

#button:hover {
  background-color: var(--color-white) !important;
  color: #4382ac;
  cursor: pointer;

}  
.calenderIcon input[type="date"]::-webkit-calendar-picker-indicator {
  filter: var(--calender-color);
}

.calenderglobalIcon input[type="date"]::-webkit-calendar-picker-indicator {
  filter: var(--calender-color);
}
.form-control:focus {
  
  box-shadow: 0 0 0 0 var(--inputbox-bg-color) !important;
}

.operationaltxt .row{
  color: var(--color-black) !important;
}

.operationalOverview .tab-content {
  background-color: var(--tab-bg-color) !important;
}

.bg-changecolor .tab-content{
  background-color: var(--tab-bg-color) !important;
}

.bg-changeoverview .tab-content{
  background-color: var(--tab-bg-color) !important;
}
.bg-changecolor .fa-info-circle,
.operationalOverview .fa-info-circle,
.operationalOverview .fa {
  color: var(--font-color-text) !important;
}

.bg-changecolor .text-white {
  color: var(--color-black) !important;
}

.fa-info-circle{
  color: var(--font-color-text) !important;
}

.facility-list table tbody tr td:nth-child(4){
  color: var(--color-white) !important;
}

.sidebar .nav-link.active {
  color: var(--font-color-text) !important;
}

.card-header,
.card-header.bg-transparent {
  
  background-color: var(--card-header-bg-color) !important;
  border-color: var(--card-border-bg-color) !important;
  color: var(--font-color-text) !important;
  border-radius: 12px!important;
}

/* p , ul li{
  color:var(--font-color-text) !important;
} */

.bg-danger {
  background-color: var(--bg-danger) !important;
}

.modalfilter .modal-body,
.modalfilter .modal-footer{
  background-color: var(--card-body-bg-color) !important;
}

.modalfilter .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border: 1px solid var(--font-color-text) !important;
}
.filter-dropdown .dropdown-toggle {
  border: 1px solid var(--font-color-text) !important;
}

.bg-changecolorpara p ,
.bg-changecolorpara ul li,
.bg-changecolorpara .card-body .mb-2{
  color: var(--font-color-text) !important;
}
.community-para .font-weight-bold{
  color: var(--font-color-text) !important;
}
.sub-para{
  color: var(--font-color-text) !important;
}
.globalBtn {
  background-color: var(--filter-header) !important;
  border-color: var(--filter-header) !important;
}
.fa-moon{
    background-color: var(--button-light-bg-color)!important;
    border-color: var(--button-light-bg-color)!important;
    color: var(--font-color-text) !important;
    padding: 8px;
    border-radius: 16px;
    cursor: pointer;
}

.fa-sun {
  background-color: var(--button-light-bg-color)!important;
    border-color: var(--button-light-bg-color)!important;
    color: var(--font-color-text) !important;
    padding: 8px;
    border-radius: 16px;
    cursor: pointer;
}

.nav-tabs .nav-link:hover {
  isolation: unset !important;
}

.bg-changecolorpara .btn-primary.btn-sm ,
.modal-btnsm .btn-primary.btn-sm{
  background-color: var(--btn-sm) !important;
  border-color: var(--btn-border-sm) !important;
}
